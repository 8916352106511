<template>
  <component :is="`icon-${name}`" />
</template>
<script>
export default {
  props: {
    name: String
  }
};
</script>
