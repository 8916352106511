<template>
  <div class="container -mt-12 pb-16">
    <app-card v-if="loading" class="p-8">
      <app-spinner class="mx-auto" />
    </app-card>

    <app-card v-else-if="error" class="p-8">
      Algo deu errado, <a @click="load">tente novamente</a>.
    </app-card>

    <app-card v-else class="grid grid-cols-accounts">
      <template v-for="account in accounts" :key="account._id">
        <!-- date -->
        <div class="py-2 px-8 border-b">
          {{ date(account.date) }}
        </div>

        <!-- email -->
        <div class="py-2 px-8 border-b">
          <a
            :href="
              `https://dashboard.stripe.com/customers/${account.billing.account}`
            "
          >
            {{ account.email }}
          </a>
        </div>

        <!-- scan count month -->
        <div class="py-2 px-8 border-b">
          <span :class="scanColor(account)">
            <span> {{ account.scanCountMonth }} </span>
            <span v-if="!account.billing.subscription"> de 30 </span>
          </span>
        </div>

        <!-- qrcode count -->
        <div class="py-2 px-8 border-b">
          <span :class="{ 'text-orange-600': account.qrcodeCount > 1 }">
            {{ account.qrcodeCount }} qr code(s)
          </span>
        </div>

        <!-- plan -->
        <div class="py-2 px-8 border-b">
          <div v-if="!account.billing.subscription"> grátis </div>
          <div v-else class="text-orange-600">
            {{ planNames[account.billing.subscription.plan] }}
          </div>
        </div>
      </template>
    </app-card>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      error: null,
      loading: true,
      accounts: null,
      planNames: {
        basic: 'básico',
        premium: 'premium'
      },
      source: axios.CancelToken.source()
    };
  },
  mounted() {
    this.load();
  },
  beforeUnmount() {
    this.source.cancel();
  },
  methods: {
    load() {
      this.loading = true;
      this.error = null;
      axios
        .get('/accounts', { cancelToken: this.source.token })
        .then(res => {
          this.accounts = res.data;
        })
        .catch(() => {
          this.error = 500;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    date(date) {
      return new Date(date).toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'short',
        timeZone: 'America/Sao_Paulo'
      });
    },
    scanColor(account) {
      return account.billing.subscription || account.scanCountMonth < 10
        ? 'text-black'
        : account.scanCountMonth < 20
        ? 'text-orange-600'
        : 'text-red-600';
    }
  }
};
</script>
