import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home';
import LogIn from '../views/log-in';
import Accounts from '../views/accounts';
import NotFound from '../views/not-found';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      account: true,
      title: 'Visão geral',
      docTitle: 'Compcodes Admin'
    }
  },
  {
    path: '/log-in',
    name: 'log-in',
    component: LogIn,
    meta: {
      auth: true,
      title: 'Entrar'
    }
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: Accounts,
    meta: {
      account: true,
      title: 'Contas'
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Página não encontrada'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPos) {
    return savedPos
      ? savedPos
      : {
          el: to.hash || '#header',
          behavior: to.name === from.name ? 'smooth' : 'auto'
        };
  }
});

export default router;
