<template>
  <span class="spinner--0 animate-spin" :class="{ white }" />
</template>
<script>
export default {
  props: {
    white: Boolean
  }
};
</script>
<style>
.spinner--0 {
  @apply block w-6 h-6 rounded-full border-4 border-black border-opacity-10;
  border-right-color: #1a73e8;
  border-top-color: #1a73e8;
}

.spinner--0.white {
  border-right-color: #ffffff;
  border-top-color: #ffffff;
}
</style>
