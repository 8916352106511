<template>
  <component
    :is="component"
    :to="to"
    :href="to"
    :class="modifiers"
    :disabled="disabled || loading"
    :type="type || 'button'"
    class="button--0"
  >
    <slot />
    <span
      v-if="loading"
      class="grid absolute inset-0 justify-center content-center"
    >
      <app-spinner :white="!!color" />
    </span>
  </component>
</template>
<script>
export default {
  props: {
    to: String,
    type: String,
    icon: Boolean,
    text: Boolean,
    large: Boolean,
    color: String,
    loading: Boolean,
    disabled: Boolean
  },
  computed: {
    component() {
      return this.to
        ? this.to.startsWith('/')
          ? 'router-link'
          : 'a'
        : 'button';
    },
    modifiers() {
      return {
        icon: this.icon,
        text: this.text,
        large: this.large,
        contained: !this.text && !this.icon,
        loading: this.loading,
        disabled: this.disabled || this.loading,
        primary: this.color === 'primary',
        white: this.color === 'white'
      };
    }
  }
};
</script>
<style>
.button--0 {
  @apply inline-block py-2 px-4 font-medium rounded uppercase text-sm tracking-wider focus:outline-none hover:no-underline relative;
}

.button--0.loading {
  @apply text-transparent !important;
}

.button--0.disabled {
  @apply opacity-70 select-none pointer-events-none;
}

.button--0.large {
  @apply py-3 px-5;
}

/* contained */

.button--0.contained {
  @apply bg-white text-black shadow hover:bg-gray-100;
}

.button--0.contained.primary {
  @apply bg-primary text-white hover:bg-primary-dark;
}

/* text */

.button--0.text {
  @apply text-primary hover:bg-black hover:bg-opacity-5;
}

.button--0.text.white {
  @apply text-white;
}

/* icon */

.button--0.icon {
  @apply p-2 hover:bg-black hover:bg-opacity-5;
}
</style>
