<template>
  <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
    <app-card class="py-8 px-4">
      <form class="max-w-sm mx-auto grid" v-on:submit.prevent="submit">
        <app-text-field :disabled="loading" v-model:options="email" />
        <app-text-field :disabled="loading" v-model:options="password" />
        <app-button
          :loading="loading"
          type="submit"
          color="primary"
          class="mt-4 justify-self-end"
        >
          continuar
        </app-button>
      </form>
    </app-card>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: {
        value: '',
        label: 'E-mail',
        placeholder: 'exemplo@gmail.com',
        autocomplete: 'username',
        inputmode: 'email',
        rules: ['required', 'email']
      },
      password: {
        value: '',
        label: 'Senha',
        type: 'password',
        autocomplete: 'current-password',
        rules: ['required']
      },
      loading: false,
      source: axios.CancelToken.source()
    };
  },
  beforeUnmount() {
    this.source.cancel();
  },
  methods: {
    submit() {
      if (this.email.error || this.password.error) {
        this.email.dirty = true;
        this.password.dirty = true;
        if (document.activeElement) document.activeElement.blur();
        return;
      }
      const body = {
        email: this.email.value,
        password: this.password.value
      };
      this.loading = true;
      axios
        .post('/sessions', body, { cancelToken: this.source.token })
        .then(res => {
          this.$root.ls('session', res.data);
        })
        .catch(err => {
          const status = (err.response && err.response.status) || 500;
          const messages = {
            '404': 'O e-mail inserido não corresponde a nenhuma conta.',
            '400': 'A senha inserida está incorreta.',
            '500': 'Algo deu errado, tente novamente.'
          };
          this.$root.snackbar(messages[status]);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
