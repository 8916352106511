import { createApp } from 'vue';
import axios from 'axios';
import App from './app';
import router from './router';
import addIcons from './plugins/add-icons';
import addComponents from './plugins/add-components';
import 'tailwindcss/tailwind.css';
import './assets/css/main.css';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const app = createApp(App).use(router);

addComponents(app);
addIcons(app);

app.mount('#app');
