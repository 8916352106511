<template>
  <div class="bg-gradient-to-tr from-primary to-teal-500" id="header">
    <div class="container">
      <div class="flex justify-between items-center py-4">
        <router-link
          to="/"
          class="text-white text-2xl hover:no-underline font-display tracking-tight"
        >
          <span class="header--0-0-0-0-0 hidden"> Compcodes Admin </span>
          <span class="header--0-0-0-0-1"> C </span>
        </router-link>
        <div v-if="$root.session" class="grid grid-flow-col gap-2">
          <app-button text color="white" to="/accounts">
            contas
          </app-button>
          <app-button text color="white" :loading="loading" v-on:click="exit">
            sair
          </app-button>
        </div>
        <div v-else></div>
      </div>
      <h1
        class="text-white pt-16"
        :class="$route.name === 'not-found' ? 'pb-16' : 'pb-28'"
      >
        {{ $route.meta.title }}
      </h1>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    exit() {
      const sessionId = this.$root.session._id;
      this.loading = true;
      axios
        .put(`/sessions/${sessionId}/active`, {})
        .then(() => {
          if (this.$root.session && this.$root.session._id === sessionId) {
            this.$root.ls('session', null);
          }
        })
        .catch(() => {
          if (this.$root.session && this.$root.session._id === sessionId) {
            this.$root.snackbar('Algo deu errado, tente novamente.');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
@media (min-width: 432px) {
  .header--0-0-0-0-0 {
    @apply inline;
  }

  .header--0-0-0-0-1 {
    @apply hidden;
  }
}
</style>
