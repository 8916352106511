<template>
  <div class="fixed bottom-2 left-2 right-2 pointer-events-none z-20">
    <transition-group tag="div" name="list" class="max-w-md mx-auto grid gap-2">
      <div
        v-for="snackbar in snackbars"
        :key="snackbar.id"
        class="bg-gray-800 p-4 text-gray-200 rounded shadow transition duration-200 pointer-events-auto select-none flex items-center"
      >
        <div class="flex-grow mr-4"> {{ snackbar.message }} </div>
        <app-icon
          name="x"
          v-on:click="dismiss(snackbar)"
          class="rounded-full p-2 -m-2 box-content cursor-pointer flex-shrink-0 text-gray-400 hover:bg-gray-700 hover:text-gray-200"
        />
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      snackbars: []
    };
  },
  methods: {
    push(message) {
      this.snackbars.unshift({
        message,
        id: Math.random().toFixed(10)
      });
      const snackbar = this.snackbars[0];
      setTimeout(() => this.dismiss(snackbar), 5000);
    },
    dismiss(snackbar) {
      this.snackbars = this.snackbars.filter(s => s !== snackbar);
    }
  }
};
</script>
<style>
.list-enter-from {
  opacity: 0;
  transform: scale(0.4);
}

.list-leave-active {
  display: none;
}
</style>
