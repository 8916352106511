<template>
  <div class="mb-4">
    <!-- label -->
    <div class="flex justify-between items-end mb-1">
      <label :for="id" class="cursor-pointer"> {{ options.label }} </label>
      <router-link
        v-if="options.link"
        :to="options.link.path"
        class="text-sm font-medium"
      >
        {{ options.link.text }}
      </router-link>
    </div>

    <!-- text field -->
    <input
      class="text-field--0-1"
      ref="input"
      :id="id"
      :disabled="disabled"
      :value="options.value"
      :type="options.type || 'text'"
      :placeholder="options.placeholder"
      :autocomplete="options.autocomplete"
      :inputmode="options.inputmode || 'text'"
      :maxlength="options.maximum"
      v-on:input="handleInput"
      v-on:focus="handleFocus"
      v-on:blur="handleBlur"
    />

    <!-- error -->
    <div
      v-if="options.error && options.dirty && !options.focus"
      class="text-sm text-red-600 mx-px px-4 pt-1"
    >
      {{ options.error }}
    </div>
  </div>
</template>
<script>
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.br';

const protocols = ['http', 'https'];

export default {
  props: {
    options: Object,
    disabled: Boolean
  },
  data() {
    return {
      id: `id${Math.random()
        .toFixed(10)
        .slice(2)}`,
      rules: {
        required: v => !!v || 'Campo obrigatório',
        email: v => isEmail(v.trim()) || 'E-mail inválido',
        url: v => isURL(v.trim(), { protocols }) || 'Endereço inválido',
        phone: v => v.length === 16 || v.length === 17 || 'Telefone inválido',
        'min-6': v => v.length >= 6 || 'Mínimo de 6 caracteres',
        'max-100': v => v.length <= 100 || 'Máximo de 100 caracteres',
        'max-160': v => v.length <= 160 || 'Máximo de 160 caracteres',
        'max-500': v => v.length <= 500 || 'Máximo de 500 caracteres'
      }
    };
  },
  created() {
    const prefix = this.options.mask && this.options.mask.prefix;
    const value = this.options.value !== prefix ? this.options.value : '';
    const dirty = !!value;
    const error = this.validate(value);
    this.$emit('update:options', { ...this.options, value, error, dirty });
  },
  mounted() {
    const el = this.$refs.input;
    const mask = this.options.mask;
    if (mask) {
      new Cleave(el, {
        ...mask,
        onValueChanged: this.handleInput
      });
    }
  },
  methods: {
    handleInput(e) {
      const value = e.target.value;
      const dirty =
        this.options.dirty ||
        !(this.options.mask && this.options.mask.prefix === value);
      const error = this.validate(value);
      this.$emit('update:options', { ...this.options, value, error, dirty });
    },
    handleFocus() {
      this.$emit('update:options', { ...this.options, focus: true });
    },
    handleBlur() {
      this.$emit('update:options', { ...this.options, focus: false });
    },
    validate(v) {
      return (
        (this.options.rules &&
          this.options.rules
            .map(rule => this.rules[rule](v))
            .filter(res => res && typeof res === 'string')[0]) ||
        null
      );
    }
  }
};
</script>
<style>
.text-field--0-1 {
  @apply py-2 px-4 bg-gray-100 rounded border outline-none focus:border-primary focus:ring-1 ring-primary w-full disabled:opacity-70;
}
</style>
