<template>
  <app-header />
  <router-view v-if="!$route.meta.account || session" class="flex-grow" />
  <app-snackbars ref="snackbars" />
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      session: null
    };
  },
  created() {
    this.$router.afterEach(to => {
      document.title = to.meta.docTitle || `${to.meta.title} - Compcodes Admin`;
      this.handleAuth(true);
    });
    window.onstorage = () => {
      this.handleAuth();
    };
    axios.interceptors.response.use(
      res => res,
      err => {
        if (axios.isCancel(err)) {
          return new Promise(() => {});
        }
        if (err.response && err.response.status === 401) {
          const session = this.ls('session');
          if (session && session.token.startsWith(err.response.data.session)) {
            this.ls('session', null);
          }
          return new Promise(() => {});
        }
        return Promise.reject(err);
      }
    );
  },
  methods: {
    ls(key, value) {
      if (value === undefined) return JSON.parse(localStorage.getItem(key));
      if (value === null) localStorage.removeItem(key);
      else localStorage.setItem(key, JSON.stringify(value));
      if (key === 'session') this.handleAuth();
    },
    handleAuth(replace = false) {
      this.session = this.ls('session');
      const bearer = this.session && 'Bearer ' + this.session.token;
      axios.defaults.headers.common.authorization = bearer;

      if (this.session && this.$route.meta.auth) {
        if (replace) this.$router.replace({ name: 'home' });
        else this.$router.push({ name: 'home' });
      } else if (!this.session && this.$route.meta.account) {
        if (replace) this.$router.replace({ name: 'log-in' });
        else this.$router.push({ name: 'log-in' });
      } else {
        return true;
      }
    },
    snackbar(...args) {
      this.$refs.snackbars.push(...args);
    }
  }
};
</script>
